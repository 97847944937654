<template>
  <module-container
    route-name="reports"
    :title="$t(sTitle)"
    :hide-sheet="hideSheet"
    :show-toolbar="!hideSheet"
    hide-back-button
    hide-new-button
  >
    <template #buttons>
      <v-btn
        :to="{ name: 'reports.dash' }"
        color="primary"
        text
        v-text="$t('back')"
        class="ml-2"
      />
    </template>

    <template #route>
      <router-view />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins, Provide } from "vue-property-decorator";
import { FilterData } from "@/modules/reports/components/ReportFilters.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";
import { ref } from "@/services/Utils";

@Component
export default class ReportsIndex extends Mixins(PaginateProviderMixin) {
  @Provide() obReportFilters = ref<FilterData | null>({});
  @Provide() obReportHeaders = ref<DataTableHeader[]>([]);

  get hideSheet() {
    const arRouteNameList = ["reports.dash"];
    return this.$route.name
      ? this.$_.includes(arRouteNameList, this.$route.name)
      : false;
  }

  get sTitle() {
    return this.$route.meta
      ? this.$_.get(this.$route.meta, "title", "reports")
      : "reports";
  }

  created() {
    this.obReportHeaders.value = [
      { text: "report.date", value: "created_at" },
      { text: "report.invoice.type", value: "invoice_type.short_name" },
      { text: "report.invoice.dgi.number", value: "order_number" },
      { text: "report.customer", value: "customer_id" },
      {
        text: "report.customer.document",
        value: "customer.firm.doc_formatted",
      },
      { text: "report.invoice.currency", value: "currency" },
      { text: "report.invoice.currency.rate", value: "rate", align: "end" },
      { text: "report.invoice.currency.convert", value: "currency_convert" },
      {
        text: "report.invoice.taxes",
        value: "taxes",
        align: "end",
      },
      {
        text: "report.total.invoice",
        value: "total_price_value",
        align: "end",
        width: 110,
      },
    ];
  }
}
</script>
